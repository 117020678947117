body {
  font-family: Helvetica;
}

p {
  font-weight: 100;
}

h1 {
  font-weight: 300;
}

.bold {
  font-weight: 500;
}

.menu {
  font-family: VT323;
  float: right;
  margin-top: 30px;
  margin-right: 20px;
  text-align: right;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu li a {
  display: inline-block;
  color: #5158ff;
  text-decoration: none;
  font-size: 20px;
}

.menu li a:hover {
  color: #f3779a;
}

.left {
  height: 1px;
  float: left;
  display: block;
  min-width: 0;
  overflow: hidden;
  width: 200px;
  margin: 0;
  padding: 0;
}

.content {
  margin-top: 15px;
  float: left;
  width: 500px;
}

.post {
  padding: 30px;
  margin: 30px;
}

/*about screen responsive css*/
@media only screen and (max-width: 600px) {
  .aboutcontent {
    width: 350px;
    margin-top: 0px;
  }

  .aboutpost {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .menu li a {
    font-size: 20px;
  }
}

img {
  /*display: block;*/
  /* max-width: 100%; */
  max-width: 100%;
  /* height: auto; */
  /*width: 500px;*/
  /*padding: 30px;
    margin: 30px;*/
}

/*art content responsive*/
@media only screen and (max-width: 600px) {
  .artcontent {
    width: 350px;
    margin-top: 0px;
  }

  .artpost {
    margin-top: 0px;
    margin-bottom: 0px;
    /* padding-top: 20px;
    padding-bottom: 20px; */
  }

  /*
  .menu li a {
    font-size: 20px;
  } */
}

.bookgrid {
  display: grid;
  grid-template-columns: auto auto auto;
}

.bookpost {
  width: 250px;
}

.bookheading {
  margin-left: 60px;
  margin-bottom: 0px;
}

.allbookspost {
  margin-left: 60px;
  /* margin-bottom: 30px; */
}

.allbookslist {
  margin-bottom: 30px;
}

.booktitle {
  text-align: center;
}

.bookcover {
  display: block;
  width: 200px;
  margin: auto;
}

/* .bookcontent {
  width: 800px;
} */

@media only screen and (max-width: 1300px) {
  .bookgrid {
    display: grid;
    grid-template-columns: auto;
  }

  .bookheading {
    margin-left: 60px;
  }

  .bookcontent {
    width: 350px;
    margin-top: 0px;
  }

  .bookpost {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .bookcover {
    margin: 0;
  }

  .booktitle {
    text-align: left;
  }

  /* .dateheader {
    width: 20px;
  } */

  /* .bookpost {
    float: left;
    width: 100px;
  } */
}

.workcontent {
  width: 1000px;
}

.workImage {
  width: 800px;
}

.workgrid {
  display: grid;
  grid-template-columns: auto;
}

@media only screen and (max-width: 600px) {
  .workcontent {
    width: 400px;
  }

  .workpost {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .hiddenonmobile {
    display: none;
  }
}

.favorite {
  color: #5158ff;
}

.bookshelf {
  margin-top: -20px;
}

.tiltextdescription {
  margin-left: 60px;
}

.tiltablecontainer {
  margin-left: 60px;
  margin-top: 30px;
}

.tiltablecontainer table,
th,
td {
  border: 0.5px solid lightgray;
  border-collapse: collapse;
}

.tiltablecontainer table {
  width: 800px;
}

.dateheader {
  width: 100px;
}

.tiltablecontainer th,
td {
  padding: 15px;
}

.tiltablecontainer td {
  font-weight: 100;
}

.tiltablecontainer th {
  text-align: left;
  font-weight: 500;
}

table .tabledate {
  /* background: #fff952; */
  background: #fff0e1;
  width: 170px;
}

@media only screen and (max-width: 1300px) {
  .tiltablecontainer table {
    width: 600px;
  }
}

@media only screen and (max-width: 600px) {
  .tiltablecontainer table {
    width: 400px;
  }
}
